NOT#backend #content-holder div:nth-child(1) section {
	background-color: #fff6a6 !important;
}
NOT.page-12 #content-holder section,
NOT.sub-page #content-holder section {
	&:nth-child(1) {
		background-color: #fff6a6 !important;
	}
}
.page-12 #content-holder section {
	&:nth-child(2) {
		//background-color: #fff6a6 !important;
		background: linear-gradient(to bottom, #fff6a6 25%, transparent 0%) !important;
		@media (min-width: 576px) {
		}
		@media (min-width: 768px) {
		}
		@media (min-width: 992px) {
			background: linear-gradient(to bottom, #fff6a6 50%, transparent 0%) !important;
		}
		@media (min-width: 1200px) {
		}
	}
}
#content-holder section.template-11  {
	background-color: #fff6a6 !important;
}

#content-holder {
	padding-bottom: 20px;
	@media (min-width: 768px) {
		padding-bottom: 70px;
	}
	position: relative;
	section {
		&.background-size-contain {
			.prb_image1,
			.prb_image1,
			.prb_image1,
			.prb_image4 {
				background-size: contain !important;
			}
		}
		&.background-size-cover {
			.prb_image1,
			.prb_image1,
			.prb_image1,
			.prb_image4 {
				background-size: cover !important;
			}
		}
		&.background-size-auto {
			.prb_image1,
			.prb_image1,
			.prb_image1,
			.prb_image4 {
				background-size: auto !important;
			}
		}
		.container > .row {
			> div {
				margin: 20px 0;
				@media (min-width: 576px) {
					margin: 20px 0;
				}
				@media (min-width: 768px) {
					margin: 20px 0;
				}
				@media (min-width: 992px) {
					margin: 20px 0;
				}
				@media (min-width: 1200px) {
					margin: 20px 0;
				}
			}
			.prb_image1,
			.prb_image2,
			.prb_image3 {
				height: 200px;
				@media (min-width: 576px) {
					height: 100%;
				}
				@media (max-width: 991px) {
					min-height: auto !important;
				}
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
			.prb_text1,
			.prb_text2,
			.prb_text3,
			.prb_text4 {
				margin: 0px;
				font-family: MikadoBlack;
				font-weight: 400;
				color: #ef413d;
				font-size: 28px;
				line-height: 34px;
				@media (min-width: 576px) {
					font-size: 21.56px;
					line-height: 26.18px;
				}
				@media (min-width: 768px) {
					font-size: 21.56px;
					line-height: 26.18px;
				}
				@media (min-width: 992px) {
					font-size: 23.20px;
					line-height: 28.22px;
				}
				@media (min-width: 1200px) {
					font-size: 28px;
					line-height: 34px;
				}
			}
			.prb_textarea1,
			.prb_textarea2,
			.prb_textarea3,
			.prb_textarea4,
			.prb_textarea5,
			.prb_textarea6 {
				overflow: hidden;
				margin: 0px;
				font-size: 18px;
				line-height: 28px;
				@media (min-width: 576px) {
					font-size: 13.86px;
					line-height: 21.56px;
				}
				@media (min-width: 768px) {
					font-size: 13.86px;
					line-height: 21.56px;
				}
				@media (min-width: 992px) {
					font-size: 14.91px;
					line-height: 23.20px;
				}
				@media (min-width: 1200px) {
					font-size: 18px;
					line-height: 28px;
				}
				p:last-child {
					margin-bottom: 0;
				}
			}
			&,
			li,
			a {
				font-family: Cabin;
				font-weight: 400;
				color: #292929;
				font-size: 18px;
				line-height: 28px;
				@media (min-width: 576px) {
					font-size: 13.86px;
					line-height: 21.56px;
				}
				@media (min-width: 768px) {
					font-size: 13.86px;
					line-height: 21.56px;
				}
				@media (min-width: 992px) {
					font-size: 14.91px;
					line-height: 23.20px;
				}
				@media (min-width: 1200px) {
					font-size: 18px;
					line-height: 28px;
				}
			}
			a,
			a:focus,
			a:hover {
				text-decoration: none;
				color: #0090d4;
				font-weight: 600;
			}
			h1 {
				font-family: MikadoBlack;
				color: #ef413d;
				font-weight: 400;
				font-size: 28px;
				line-height: 40px;
				@media (min-width: 576px) {
					font-size: 21.56px;
					line-height: 30.80px;
				}
				@media (min-width: 768px) {
					font-size: 21.56px;
					line-height: 30.80px;
				}
				@media (min-width: 992px) {
					font-size: 23.20px;
					line-height: 33.14px;
				}
				@media (min-width: 1200px) {
					font-size: 28px;
					line-height: 40px;
				}
			}
			p {
				margin-bottom:10px;
			}
			b,
			strong {
				font-weight: 600;
			}
		}
		&.template-11 {
			margin-bottom: 20px;
			padding-bottom: 20px;
			.container > .row {
				margin-top: 0px;
				.prb_text1 {
					margin: 20px 0 0;
					font-family: MikadoBlack;
					font-weight: 400;
					color: #f47920;
					font-size: 40px;
					line-height: 46px;
					@media (min-width: 576px) {
						font-size: 30.80px;
						line-height: 35.42px;
					}
					@media (min-width: 768px) {
						font-size: 30.80px;
						line-height: 35.42px;
					}
					@media (min-width: 992px) {
						font-size: 33.20px;
						line-height: 38.18px;
					}
					@media (min-width: 1200px) {
						font-size: 40px;
						line-height: 46px;
					}
				}
				.prb_textarea1 {
					margin: 20px 0 0 30px;
					font-size: 20px;
					font-weight: 600;
					line-height: 34px;
					@media (min-width: 576px) {
						margin: 10px 0 0 30px;
						font-size: 15.40px;
						line-height: 26.18px;
					}
					@media (min-width: 768px) {
						font-size: 15.40px;
						line-height: 26.18px;
					}
					@media (min-width: 992px) {
						margin: 20px 0 20px 30px;
						font-size: 16.57px;
						line-height: 28.17px;
					}
					@media (min-width: 1200px) {
						font-size: 20px;
						line-height: 34px;
					}	
				}
				.prb_image1 {
					@media (max-width: 575px) {
						margin-top: -10px;
						height: 300px;
					}
				}
			}
		}
		&.template-12 {
			.container > .row {
				.prb_text1,
				.prb_text2 {
					margin: -3px 0 0;
					font-family: MikadoBlack;
					font-weight: 400;
					color: #ef413d;
				}
			}
		}
		&.template-13,
		&.template-14 {
			.container > .row {
				.prb_text1 {
					margin: -3px 0 0;
					font-family: MikadoBlack;
					font-weight: 400;
					color: #ef413d;
				}
				.prb_image1 {
					@media (max-width: 575px) {
					}
				}
				@media (max-width: 575px) {
					.box > .row.row-eq-height {
						flex-direction: column-reverse;
					}
					.prb_image1 {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.template-15, 
		&.template-21 {
			.container > .row {
				.prb_text1 {
					margin: -3px 0 0;
					font-family: MikadoBlack;
					font-weight: 400;
					color: #ef413d;
				}
				.prb_image1 {
					@media (max-width: 575px) {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.template-16,
		&.template-22 {
			.container > .row {
				.prb_text1,
				.prb_text2,
				.prb_text3 {
					margin: -3px 0 0;
					font-family: MikadoBlack;
					font-weight: 400;
					color: #ef413d;
				}
				.prb_image1,
				.prb_image2,
				.prb_image3 {
					margin: 30px 0;
					text-align: center;
					height: auto;
					img {
						height: 100%;
						width: 100%;
						@media (min-width: 576px) {
							
						}
					}
				}
			}

		}
		&.template-17 {
			.container > .row {
				.prb_text1,
				.prb_text2 {
						margin: -3px 0 0;
				}
				@media (max-width: 575px) {
					.box > .row.row-eq-height {
						flex-direction: column-reverse;
					}
					.prb_image1 {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.template-18 {
			.container > .row {
				.prb_text2 {
						margin: -3px 0 0;
				}
				@media (max-width: 575px) {
					.box > .row.row-eq-height {
						flex-direction: column-reverse;
					}
					.prb_image2 {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.template-19 {
			.container > .row {
				.prb_text1 {
						margin: -3px 0 0;
				}
				.prb_image1 {
					@media (max-width: 575px) {
						margin-bottom: 20px;
					}
				}
			}
		}
		&.template-20 {
			.eb-devider {
				@media (min-width: 576px) {
					height: 3px;
					width: 100%;
					background-color: #eaeaea;
					margin: 20px 0;
				}
			}
		}
		&.template-22 {
			.container > .row {
				.prb_image1,
				.prb_image2,
				.prb_image3 {
					margin: 20px 0 0;
				}
			}
		}
		&.template-11,
		&.template-12,
		&.template-13,
		&.template-14,
		&.template-15,
		&.template-16,
		&.template-17,
		&.template-18,
		&.template-19,
		&.template-20,
		&.template-21,
		&.template-22,
		&.template-23 {
			&.title-blue {
				.container > .row {
					.prb_text1 {
						color: #0090d4;
					}
				}
			}
		}
	}
	.box {
		min-width: 100%;
	}
	.box-white {
		.box {
			background-color: #ffffff;
			box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
			border-radius: 7px;
			overflow: hidden;
			padding: 20px;
			@media (min-width: 576px) {
				padding: 40px;
			}
		}
	}
	.box-yellow {
		.box {
			background-color: #fff6a6;
			box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
			border-radius: 7px;
			overflow: hidden;
			padding: 20px;
			@media (min-width: 576px) {
				padding: 40px;
			}
		}
	}
	section .container .row .nijsbrief {
		box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.1);
		border-radius: 7px;
		overflow: hidden;
		height: 100%;
		background-color: #d9efe1;
		.content-wrapper {
			align-items: flex-start;
			margin: 20px;
			@media (min-width: 576px) {

			}
			@media (min-width: 768px) {
				margin: 30.80px
			}
			@media (min-width: 992px) {
				//total height: 289.98px;
				margin: 33.14px
			}
			@media (min-width: 1200px) {
				//total height: 350px
				margin: 40px;
			}
			.prb_text2 {
				color: #0090d4;
				margin-bottom: 20px;
			}
			.text {
				font-weight: 600;
			}
			.image {
				height: 100%;
				max-width: 100%;
				margin: 0 0 0 -15px;
				text-align: center;
				img {
					width: 100%;
					@media (min-width: 576px) {
						width: 100%;
					}
				}
			}
			.actions {
				margin: 30px 0 0px;
				text-align: left;
				.form {
					overflow: hidden;
					position: relative;
					input[name="email"] {
						border-radius: 20px;
						border-width: 0px;
						padding: 0px 50px 0px 20px;
						width: 100%;
						font-size: 17px;
						line-height: 40px;
						@media (min-width: 576px) {
							font-size: 13.09px;
						}
						@media (min-width: 768px) {
							font-size: 13.09px;
						}
						@media (min-width: 992px) {
							font-size: 14.11px;
						}
						@media (min-width: 1200px) {
							font-size: 17px;
						}
						color: #949494;
						&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
							color: #949494;
						}
					}
					.submit {
						position: absolute;
						right: 4px;
						top: 4px;
						width: 32px;
						height: 32px;
						background-color: #0090d4;
						text-align: center;
						line-height: 32px;
						border-radius: 16px;
						i.far {
							color: #ffffff;
						}
						cursor: pointer;
						&:hover {
							background-color: #0056b3;
						}
					}
				}
			}
		}
	}
	.template-box-5 {
		height: 100% !important;
		box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.1);
		border-radius: 7px;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		.content-wrapper {
			display: flex;
			//justify-content: center;
			flex-direction: column;
			align-items: center;
			overflow: hidden;
			height: 100%;
			min-height: 320px;
			@media (min-width: 576px) {
				min-height: 250px;
			}
			@media (min-width: 768px) {
				min-height: 300px;
			}
			@media (min-width: 992px) {
			}
			@media (min-width: 1200px) {
			}
			position: relative;
			.heading {
				margin-bottom: auto;
				margin-top: 28px;
				text-align: center;
				h2 {
					color: #ffe600;
					font-size: 38px;
					@media (min-width: 576px) {
						font-size: 29.26px;
					}
					@media (min-width: 768px) {
						font-size: 29.26px;
					}
					@media (min-width: 992px) {
						font-size: 31.48px;
					}
					@media (min-width: 1200px) {
						font-size: 38px;
					}
				}
			}
			.actions {
				margin-top: auto;
				margin-bottom: 28px;
				text-align: center;
			}
		}
		&.games {
			background-color: transparent;
			background-size: cover !important;
			.content-wrapper {
				.heading {
					h2 {
						color: #0090d4;
					}
				}
			}
	
		}
		&.music {
			background-color: #cce9f6;
			background-size: auto;
			.content-wrapper {
				.heading {
					h2 {
						color: #0090d4;
					}
				}
			}
		}
	}
	.stripe {
		display: inline-block;
		background-image: url('/css/img/stripe.png');
		background-repeat: no-repeat;
		height: 4px;
		width: 20px;
		margin: 2px 0 5px;
	}
}
body.page-12 {
	#content-holder {
		section {
			&.template-11 {
				margin-bottom: 0px;
				padding-bottom: 0px;
				NOT.container > .row {
					.prb_text1,
					.prb_text2,
					.prb_text3,
					.prb_text4 {
						font-size:40px;
						line-height: 48px;
						@media (min-width: 576px) {
							font-size:35.42px;
							line-height: 42.35px;
						}
						@media (min-width: 768px) {
							font-size:35.42px;
							line-height: 42.35px;
						}
						@media (min-width: 992px) {
							font-size:39px;
							line-height: 45.65px;
						}
						@media (min-width: 1200px) {
							font-size:40px;
							line-height: 48px;
						}
					}
				}
			}
		}
	}
}