@font-face { font-family: MikadoBlack; 
    src: url("/css/fonts/MikadoBlack.eot"); 
    src: url("/css/fonts/MikadoBlack.eot?#iefix") format("embedded-opentype"), 
    url("/css/fonts/MikadoBlack.woff2") format("woff2"), 
    url("/css/fonts/MikadoBlack.woff") format("woff"), 
    url("/css/fonts/MikadoBlack.ttf") format("truetype"); 
    font-weight: normal; 
    font-style: normal; 
}

body .panel-heading+.panel-body {
	padding-top: 20px !important;
}
body .k-textbox {
	width: 100%;
}

.title_header {
    padding: 4px 12px;
    margin: 11px 5px 5px 30px;
    font-size: 20px;
    float: left;
}
.signin .content-box {
    background-color: #f3f3f4!important;
    padding: 15px!important;
}
body .k-window,
body .k-window-content {
    border-color: #1ab394 !important;
	border-radius: 0px !important;
}
.panel-title {
	color:#fff;
}
.panel-heading {
	background-color:#1ab394 !important;
	border-radius: 0px;
}
.panel-title {
	color:#fff;
}

.panel-body {
	color:#000;
	padding:20px;
}

.panel-default {
	border:none;
}
body .k-widget.k-window .k-window-content *,
body .k-widget.k-window .k-window-content :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body .form-group.has-error .validator-message {
    display: inline !important;
}
body .form-group .validator-message {
    display: none;
    color: #a94442;
    font-size: 12px;
}
body .form-group .mandatory:after {
    content: " *";
    color: #a94442;
}
body div.k-window-content {
	padding: 0;
}
body .k-window .panel {
	border-radius: 0px;
}
body .k-window-titleless .panel {
	margin-bottom: 0px; /* scrollbar issue fix */
	background-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
}
body .panel-body .actions {
	text-align: right;
}
body .panel-heading .title {
	font-weight: normal;
	color: #fff;
	font-size: 16px;
	display: inline-block;
	line-height: 33px; /* buttons */
}
body .panel-heading .btn {
	display: inline-block;
	float: right;
	margin-left: 15px;
}
.page-heading .title {
	color: #333;
	font-weight: normal;
	font-size: 16px;
	display: inline-block;
	line-height: 30px;
}
NOTbody .page-heading .btn {
	float: right;
	margin-left: 10px;
}

body .panel-heading {
	padding: 4px 15px;
}

#cropContainerModal1,
#cropContainerModal2,
#cropContainerModal3,
#cropContainerModal4 {
	position: fixed;
}
#croppicModal {
	z-index:11000 !important;
}
#prb_image_container1,
#prb_image_container2,
#prb_image_container3,
#prb_image_container4 {
	position: relative;
	height: 100px;
}
#prb_image_container1 > div,
#prb_image_container2 > div,
#prb_image_container3 > div,
#prb_image_container4 > div {
	float: left;
	margin-right: 10px;
	position: relative;
	width: 100px;
	height: 100px;
	border: 1px solid #e5e6e7;
	overflow: hidden;
    text-align: center;        		
}

#form-content #prb_image_container1 > div,
#form-content #prb_image_container2 > div,
#form-content #prb_image_container3 > div,
#form-content #prb_image_container4 > div {
	margin-bottom: 10px;
}	

#prb_image_container1 i.fa,
#prb_image_container2 i.fa,
#prb_image_container3 i.fa,
#prb_image_container4 i.fa {
	position: absolute;
	top: 37px;
	left: 38px;
	cursor: pointer;
	color: #1ab394;
	font-size: 24px;
}
#prb_image_container1 i.fa:hover,
#prb_image_container2 i.fa:hover,
#prb_image_container3 i.fa:hover,
#prb_image_container4 i.fa:hover {
	color: #18a689;	
}
#prb_image1,
#prb_image2,
#prb_image3,
#prb_image4 {
	display: none;
	position: relative;
	max-height: 100%;
}

/* do not create subfolders due to Kendo File/ImageBrowser subdirectory slash is html encoded bug */
.k-filebrowser-toolbar > .k-toolbar-wrap .k-button-icon:nth-child(2) {
	display: none;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.dt-bootstrap select.input-sm {
    height: 30px;
    line-height: 14px;
}
body .dd-content,
body .dd-handle	{
	color: #76838f;
}
body .table a {
    text-decoration: none;
}
body .table-row-click tr {
	cursor: pointer;
}
body .page-heading .actions {
	margin-top: 38px;
}
body .dataTable td.sort-order {
	cursor: ns-resize;
}
body .dataTable .sort-order  .fa-arrows-v {
	font-size: 18px;
	opacity: 0.7;
}
body .dataTable td.actions {
	text-align: right;
}
body .dataTable td.actions > * {
	margin: 0 0 7px 7px;
}
body .btn-primary.active.focus, 
body .btn-primary.active:focus, 
body .btn-primary.active:hover, 
body .btn-primary:active.focus, 
body .btn-primary:active:focus, 
body .btn-primary:active:hover, 
body .open>.dropdown-toggle.btn-primary.focus, 
body .open>.dropdown-toggle.btn-primary:focus, 
body .open>.dropdown-toggle.btn-primary:hover {
	background-color: #18a689;
    border-color: #18a689;
    color: #FFFFFF;
}

body .dataTable td.actions > *:nth-last-child() {
	margin: 0 0 7px !important;
}
body .inmodal .modal-header {
	padding: 15px 20px;
}
body #eb-confirm {
	.modal-title {
		text-align: center;
	}
	.modal-body {
		text-align: center;
		font-size: 14px;
		padding: 20px;
	}
	.modal-footer {
		text-align: center;
		padding: 20px;
	}

}
body .checkbox.category {
	padding-right: 30px;
}
body #toast-container > .toast-warning:before {
	content: "\f0e7";
	font-family: 'Font Awesome 5 Pro';
}
body #toast-container > .toast-error:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f071";
}
body #toast-container > .toast-info:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f005";
}
body #toast-container > .toast-success:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f00c";
}
body #content-holder .row-eq-height > .col-12 { 	
	width: 100%;
}