.eb-btn {
	display: inline-block;
	font-family: 'Cabin';
	font-weight: 600;
	background-color: #f47920;
	color: #ffffff !important;
	padding: 0px 20px;
	border-radius: 24px;
	box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
	margin-top: 12px; //box-shadow + marge
	margin-bottom: 8px;
	cursor: pointer;
	white-space: nowrap;
	font-size: 18px;
	line-height: 40px !important;
	@media (min-width: 576px) {
		font-size: 13.86px;
		line-height: 30.80px;
	}
	@media (min-width: 768px) {
		font-size: 13.86px;
		line-height: 30.80px;
	}
	@media (min-width: 992px) {
		font-size: 14.50px;
		line-height: 32.24px;
	}
	@media (min-width: 1200px) {
		font-size: 18px;
		line-height: 40px;
	}
	&:hover {
		text-decoration: none;
		color: #ffffff;
		background-color: #e73629;
	}
	i.far,
	i.fal,
	i.fas {
		color: #ffe600;
		padding-left: 10px;
		font-size: 19px;
		line-height: 21px;
		@media (min-width: 576px) {
			font-size: 14.63px;
			line-height: 16.17px;
		}
		@media (min-width: 768px) {
			font-size: 14.63px;
			line-height: 16.17px;
		}
		@media (min-width: 992px) {
			font-size: 15.77px;
			line-height: 17.43px;
		}
		@media (min-width: 1200px) {
			font-size: 19px;
			line-height: 21px;
		}
	}
	i.fab {
		line-height: 40px;
		@media (min-width: 576px) {
			line-height: 30.80px;
		}
		@media (min-width: 768px) {
			line-height: 30.80px;
		}
		@media (min-width: 992px) {
			line-height: 33.20px;
		}
		@media (min-width: 1200px) {
			line-height: 40px;
		}
	}
	&.blue {
		background-color: #0090d4;
		color: #ffffff !important;
		&:hover {
			text-decoration: none;
			background-color: #0056b3;
		}
	}
	&.yellow {
		background-color: #ffe600;
		color: #0090d4 !important;
		i.fas {
			color: #f47920;
		}
		&:hover {
			text-decoration: none;
			background-color: #e0ce02;
		}
	}
	&.white {
		background-color: #ffffff;
		color: #0090d4 !important;
		i.far,
		i.fas {
			color: #f47920;
		}
		&:hover {
			text-decoration: none;
			background-color: #f2f2f2;
		}
	}
}
body.paint,
body.print_material {
	.eb-btn {
		@media (max-width: 576px) {
			font-size: 13.86px;
			line-height: 30.80px;
			i.far,
			i.fal,
			i.fas {
				font-size: 13.86px;
				line-height: 30.80px;
			}
		}
	}
}